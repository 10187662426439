<template>
  <aside class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 bg-white" id="sidenav-main" style="z-index: 3">
    <div class="sidenav-header">
      <i @click="toggle" class="fas fa-times p-3 cursor-pointer text-black opacity-5 position-absolute end-0 top-0 d-xl-none" :class="this.$store.state.closeclass" aria-hidden="true" id="iconSidenav"></i>
      <a class="navbar-brand m-0" href="#" target="">
        <img :src="imageIcon" class="navbar-brand-img h-100" alt="main_logo">
        <span class="ms-1 font-weight-bold">Paybender Dashboard</span>
      </a>
    </div>
    <hr class="horizontal dark mt-0">
    <div class="collapse navbar-collapse w-auto h-auto" id="sidenav-collapse-main" v-if="userRole=='admin'">
      <ul class="navbar-nav">
        <li class="nav-item">
          <MenuItem @toggle="toggle" menuname="Merchant Summary" menulink="/dashboard" menuicon="fas fa-home text-dark" group-title="Menus"></MenuItem>
          <MenuItem @toggle="toggle" menuname="Transaction Chart" menulink="/transaction-chart" menuicon="fas fa-message text-dark" ></MenuItem>
          <MenuItem @toggle="toggle" menuname="PayIn Transaction" menulink="/payin-transaksi" menuicon="fas fa-wallet text-dark"></MenuItem>
          <MenuItem @toggle="toggle" menuname="PayOut Transaction" menulink="/payout-transaksi" menuicon="fas fa-money-bill-transfer text-dark"></MenuItem>
          <!-- <MenuItem v-if="isSplitPayout == 1" @toggle="toggle" menuname="Wallet" menulink="/balance-wallet" menuicon="fas fa-message text-dark" is-sub-menu></MenuItem> -->
          <!-- <MenuItem v-if="isSplitPayout == 1" @toggle="toggle" menuname="Disbursement" menulink="/balance-disbursement" menuicon="fas fa-message text-dark" is-sub-menu></MenuItem> -->
          <MenuItem @toggle="toggle" menuname="Balance History" menulink="/balance-history" menuicon="fas fa-message text-dark" is-sub-menu></MenuItem>
          <MenuItem @toggle="toggle" menuname="Check Transaction" menulink="/check-transaction" menuicon="fas fa-message text-dark"></MenuItem>
          <MenuItem @toggle="toggle" menuname="Distribute Balance" menulink="/distribute-balance" menuicon="fas fa-money-bill-transfer text-dark"></MenuItem>
          <MenuItem @toggle="toggle" menuname="Manual Disbursement" menulink="/simulator-manual-disbursement" menuicon="fas fa-message text-dark"></MenuItem>
          <MenuItem @toggle="toggle" menuname="Callback Request" menulink="/simulator-callback-request" menuicon="fas fa-message text-dark"></MenuItem>
          <MenuItem @toggle="toggle" menuname="Failed PayIn Transaction" menulink="/failed-payin-transaksi" menuicon="fas fa-wallet text-dark"></MenuItem>
          <MenuItem @toggle="toggle" menuname="Overdraft Summary" menulink="/overdraft-summary" menuicon="fas fa-wallet text-dark"></MenuItem>
          <MenuItem @toggle="toggle" menuname="Overdraft History" menulink="/overdraft-history" menuicon="fas fa-wallet text-dark"></MenuItem>
          <MenuItem @toggle="toggle" menuname="Settlement" menulink="/settlement" menuicon="fas fa-money-bill-transfer text-dark" group-title="Settlement"></MenuItem>
          <!-- <MenuItem @toggle="toggle" menuname="Settlement Report" menulink="/settlement-report" menuicon="fas fa-money-bill-transfer text-dark" ></MenuItem> -->
          <MenuItem @toggle="toggle" menuname="Topup Request" menulink="/topuprequest" menuicon="fas fa-money-bill-transfer text-dark" group-title="Topup"></MenuItem>
          <MenuItem @toggle="toggle" menuname="Profile" menulink="/profile" menuicon="fas fa-user text-dark" group-title="Account"></MenuItem>
          <MenuItem @toggle="toggle" menuname="ListUsers" menulink="/webuser" menuicon="fas fa-user text-dark"></MenuItem>
          <MenuItem @toggle="toggle" menuname="Products" menulink="/products" menuicon="fas fa-handshake text-dark" group-title="Products"></MenuItem>
          <!-- <MenuItem @toggle="toggle" menuname="Products Mapping" menulink="/productmapping" menuicon="fas fa-handshake text-dark"></MenuItem> -->
          <!-- <MenuItem @toggle="toggle" menuname="Price" menulink="/productprice" menuicon="fas fa-handshake text-dark"></MenuItem> -->
          <!-- <MenuItem @toggle="toggle" menuname="Merchant List" menulink="/merchantlist" menuicon="fas fa-handshake text-dark" group-title="Payout Setting"></MenuItem>
            <MenuItem @toggle="toggle" menuname="E2Pay Wallet" menulink="/checkwallet" menuicon="fas fa-handshake text-dark"></MenuItem> -->
            <!-- <MenuItem @toggle="toggle" menuname="Partners" menulink="/client" menuicon="fas fa-handshake text-dark" group-title="Setting"></MenuItem> -->
          <MenuItem @toggle="toggle" menuname="Partners" menulink="/partner" menuicon="fas fa-handshake text-dark" group-title="Setting"></MenuItem>
            <!-- <MenuItem @toggle="toggle" menuname="PG Setting" menulink="/pgsetting" menuicon="fas fa-cog text-dark"></MenuItem> -->
          <MenuItem @toggle="toggle" menuname="Partner Whitelist IP" menulink="/whitelist" menuicon="fas fa-cog text-dark"></MenuItem>
          <MenuItem @toggle="toggle" menuname="Manage Information" menulink="/manage-information" menuicon="fas fa-info text-dark"></MenuItem>
          <MenuItem @toggle="toggle" menuname="Fee Calculator" menulink="/feecalculator" menuicon="fas fa-user text-dark" group-title="Tools"></MenuItem>
          <MenuItem @toggle="toggle" menuname="Partner Req Log" menulink="/requestlog" menuicon="fas fa-handshake text-dark" group-title="Logs Data"></MenuItem>
          <MenuItem @toggle="toggle" menuname="PG Request Log" menulink="/pglog" menuicon="fas fa-cog text-dark"></MenuItem>
          <MenuItem @toggle="toggle" menuname="PG Incoming Log" menulink="/pgincominglog" menuicon="fas fa-cog text-dark"></MenuItem>
          <MenuItem @toggle="toggle" menuname="MBayar Log" menulink="/payoutlog" menuicon="fas fa-cog text-dark"></MenuItem>
          <MenuItem @toggle="toggle" menuname="Client Report" menulink="/clientreport" menuicon="fas fa-cog text-dark"></MenuItem>
          <MenuItem @toggle="toggle" menuname="Textlog Viewer" menulink="/textlogviewer" menuicon="fas fa-cog text-dark"></MenuItem>
          <!-- <MenuItem @toggle="toggle" menuname="Payin" menulink="/simulator-payin" menuicon="fas fa-home text-dark" group-title="Simulator"></MenuItem>
          <MenuItem @toggle="toggle" menuname="Payout" menulink="/simulator-payout" menuicon="fas fa-message text-dark"></MenuItem> -->
          <!-- <MenuItem @toggle="toggle" menuname="Payin" menulink="/simulator-new-payin" menuicon="fas fa-home text-dark" group-title="Simulator"></MenuItem>
          <MenuItem @toggle="toggle" menuname="Payout" menulink="/simulator-new-payout" menuicon="fas fa-message text-dark"></MenuItem> -->
        </li>
      </ul>
    </div>
    <div class="collapse navbar-collapse h-auto " id="sidenav-collapse-main-user" v-if="userRole=='user' || userRole=='merchant' || userRole=='referral'">
      <ul class="navbar-nav">
        <li class="nav-item">
          <MenuItem @toggle="toggle" menuname="Merchant Summary" menulink="/dashboard" menuicon="fas fa-home text-dark" group-title="Menus"></MenuItem>
          <MenuItem @toggle="toggle" menuname="Transaction Chart" menulink="/transaction-chart" menuicon="fas fa-message text-dark" ></MenuItem>
          <MenuItem menuname="PayIn Transaction" menulink="/payin-transaksi" menuicon="fas fa-wallet text-dark"></MenuItem>
          <MenuItem menuname="PayOut Transaction" menulink="/payout-transaksi" menuicon="fas fa-money-bill-transfer text-dark"></MenuItem>
          <!-- <MenuItem  v-if="isSplitPayout == 1" menuname="Wallet" menulink="/balance-wallet" menuicon="fas fa-message text-dark" is-sub-menu></MenuItem>
          <MenuItem  v-if="isSplitPayout == 1" menuname="Disbursement" menulink="/balance-disbursement" menuicon="fas fa-message text-dark" is-sub-menu></MenuItem> -->
          <MenuItem @toggle="toggle" menuname="Balance History" menulink="/balance-history" menuicon="fas fa-message text-dark" is-sub-menu></MenuItem>
          <MenuItem @toggle="toggle" menuname="Settlement" menulink="/settlement" menuicon="fas fa-money-bill-transfer text-dark" group-title="Settlement"></MenuItem>
          <MenuItem menuname="Profile" menulink="/profile" menuicon="fas fa-user text-dark" group-title="Account"></MenuItem>
        </li>
      </ul>
    </div>
    <div class="collapse navbar-collapse h-auto " id="sidenav-collapse-main-guest" v-if="userRole=='guest'">
      <ul class="navbar-nav">
        <li class="nav-item">
          <MenuItem menuname="Profile" menulink="/profile" menuicon="fas fa-user text-dark"></MenuItem>
        </li>
      </ul>
    </div>
  </aside>
</template>

<script>
import MenuItem from "@/components/MenuItem";
import DashboardService from "@/services/dashboard.service";
export default {
  name: "DashboardLeftMenu",
  props:{
    show: Boolean
  },
  components: {
    MenuItem
  },
  data:()=>({
    imageIcon: require('@/assets/img/logo-paybender.png'),
    userRole : DashboardService.getUserRole(),
    visible: true,
    isSplitPayout: 1,
  }),
  methods:{
    toggle(){
      this.$store.state.menucollapse = !this.$store.state.menucollapse;
      if(!this.$store.state.menucollapse){
        document.body.classList.remove('g-sidenav-pinned')
        this.$store.state.menuclass = 'navbar-expand-xs';
        this.$store.state.sandwichIcon = '';
        this.$store.state.closeclass = 'd-none';
      }else{
        document.body.classList.add('g-sidenav-pinned')
        this.$store.state.closeclass = '';
        this.$store.state.menuclass = 'navbar-expand-xl';
        this.$store.state.sandwichIcon = 'g-sidenav-pinned';
      }
    }
  },
  computed:{
  },
  mounted() {
    const loggedIn = localStorage.getItem('user');
    if(loggedIn != null){
        let dataUser = JSON.parse(loggedIn);
        this.isSplitPayout = dataUser.split_payout;
        console.log("dataUser", dataUser);
        console.log("userRole", this.userRole);
    }
  },
}
</script>

<style scoped>

</style>